import {
  Box,
  ChakraProvider,
  Grid,
  Text,
  VStack,
  extendTheme, type ThemeConfig
} from "@chakra-ui/react";
import "@fontsource/ibm-plex-mono";
import * as React from "react";
import DownloadButton from "./DownloadButton";


export const App = () => {
  const [windowSize, setWindowSize] = React.useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  React.useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  }
  
  // 3. extend the theme
  const theme = extendTheme({ config,    styles: {
    global: {
      body: {
        bg: "#2e180b", // Customize the dark body background color here
        fontFamily: "IBM Plex Mono", // Set your desired font here
      },
    },
  },
})
  
  
  
  return (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
      <VStack justify="center" spacing={8}>
          <VStack>
            <Text marginBottom={"5%"} maxWidth={"60%"} fontFamily={"IBM Plex Mono"} color={"#FFFFFF"}>
              do you have feelings?<br/> <br/>do you care about your friends?<br/> <br/>download the beta and build your folsom family
            </Text>
            <DownloadButton/>
          </VStack> 
        </VStack>
      </Grid>
      <Box style={{position: "fixed", bottom: 0, width: "100%", display: "flex", justifyContent: "center"}}>
        <Text fontSize={"xs"} fontFamily={"IBM Plex Mono"} color={"#FFFFFF"}>
          2023 Ordway Projects, Inc. all rights reserved. questions? comments? email hi@folsomfeelings.com
        </Text>
      </Box>
    </Box>
  </ChakraProvider>
  )
}
