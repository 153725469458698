import {
  Button,
  FormControl,
  HStack,
  VStack
} from '@chakra-ui/react';
import React from "react";

const DownloadButton: React.FC = () => {
  const handleClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
    event.preventDefault();
    window.location.href = 'https://apps.apple.com/us/app/folsom-feelings/id6448472742';

  };
  return (
  <FormControl>
    <VStack>
      <HStack>
      <Button onClick={handleClick} type='submit'>download folsom</Button>
      </HStack>
    </VStack>
  </FormControl>
  )
}

export default DownloadButton